import "./UserAvatarDefault.css";

function UserAvatarDefault() {
    return (
        <figure className="user-avatar--default">
            <svg className="user-avatar--default__icon" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" /></svg>
        </figure>
    );
}

export default UserAvatarDefault;
